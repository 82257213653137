import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { RestMessage } from '@app/core/types';
import { FiltersService } from '@app/shared/services/filters.service';
import { NgForageCache } from 'ngforage';
import { Subject, Subscription, Observable } from 'rxjs';
import { getSafeLocalStorage } from '@app/core/helpers';
import { map } from 'rxjs/operators';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { GlobalFilterHelperService } from './global-filters-helper.service';
import { GlobalFilterMapService } from './global-filters.map.service';
import { GlobalFilterService } from './global-filters.service';
import moment from 'moment';
import { monthNames } from 'projects/cvreporting/src/app/features/cvreporting/report-generator/report_items';
import { TranslationService } from '@reflecxfeatures/Survey/translation-editor/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/core';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
@Injectable()
export class GlobalFilterApiService implements OnDestroy {
  date_groups: any = [];
  dispositions: any = [];
  version_rg: any = [];
  multi_orgs: any = []; // config.property
  multi_models: any = [];
  orgs: any = []; // config.property
  models: any = []; // config.property
  versions: any = []; // config.property
  languages: any = []; // config.property
  categories: any = []; // config.property
  surveyQuestions: any = []; // config.property
  surveyResponses: any = []; // config.property
  subscriptions: Subscription[] = [];
  apiCallState = {};
  permissions: NgxPermissionsObject;
  enableDailyReporting: boolean;
  public apiChange = new Subject();
  public apiConfig: any = {
    org: this.getOrgs.bind(this),
    multi_org: this.getOrgs.bind(this),
    model: this.getVindecoderData.bind(this),
    multi_model: this.getVindecoderData.bind(this),
    version: this.getVersion.bind(this),
    version_qp: this.getVersion.bind(this),
    language: this.getLanguages.bind(this),
    surveyResponse: this.getSurveyResponse.bind(this),
    surveyQuestion: this.getSurveyQuestion.bind(this),
    category: this.getCategories.bind(this),
    date_group: this.getDateGroups.bind(this),
    version_rg: this.getUpdatedVersion.bind(this),
    disposition: this.getDispositions.bind(this),
  };
  allSurveyVersions: any;
  apiCallCache: number = 0;
  constructor(
    public http: HttpClient,
    public gbfService: GlobalFilterService,
    public filterService: FiltersService,
    public gbfMapService: GlobalFilterMapService,
    private translateService: TranslateService,
    public ngCache: NgForageCache,
    public gbfConfig: GlobalFilterConfigService,
    public gbfHelperService: GlobalFilterHelperService,
    public ngxPermission: NgxPermissionsService,
    private authService: AuthenticationService
  ) {
    this.permissions = this.ngxPermission.getPermissions();
    this.subscriptions.push(
      gbfService.gbfApiCall.subscribe((configs: any[]) => {
        configs.forEach((config: any) => {
          if (this.apiConfig[config.name]) {
            if (this.gbfConfig.filterConfig.initialStates[config.name]) {
              this.gbfService[config.name] = this.gbfConfig.filterConfig.initialStates[config.name];
            }
            this.apiCallCache = this.apiCallCache + 1;
            this.apiConfig[config.name](config);
            if (this.apiCallCache == 1) {
              this.gbfService.gbfSaveConfiguration.next(true);
              setTimeout(() => {
                this.apiCallCache = 0;
              }, 500);
            }
            // if (!this.apiConfig[config.name]) {
            // this.ngCache.setCached(`gbf_${config.name}`, '{}', 300000);
            // }
            if (this.gbfConfig.filterConfig.initialStates[config.name]) {
              localStorage.removeItem(config.localStorageKey);
            }
          }
        });
      })
    );
    this.enableDailyReporting = this.permissions[Permission.REPORT_REPORTGENERATOR_DAILYREPORTING] ? true : false;
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setDispositions(config: any, records: any[]) {
    let dispositions: any =
      Array.isArray(this.gbfService.disposition) && this.gbfService.disposition.length > 0
        ? this.gbfService.disposition
        : getSafeLocalStorage(config.localStorageKey, true, new Array());
    dispositions = dispositions
      .filter((d: any) => {
        if (records.find((x: any) => x.value === d.value)) {
          return d;
        }
        return null;
      })
      .filter(Boolean);
    records = records.map((x: any) => {
      if (dispositions.find((y: any) => x.value === y.value)) {
        x.IsChecked = true;
      }
      return x;
    });
    this.dispositions = records;
    this.gbfService.disposition = dispositions;
    localStorage.setItem(config.localStorageKey, JSON.stringify(dispositions));
    this.apiChange.next(config);
  }
  getDispositions(config: any) {
    this.apiChange.next(config);
  }
  // getUpdatedVersion(config: any) {
  //   if (this.gbfService.version) {
  //     this.version_rg.push({
  //       Type: 'Version',
  //       Key: this.gbfService.version.versionName,
  //       Name: this.gbfService.version.versionName,
  //       label: this.gbfService.version.versionName,
  //     });
  //   }
  //   this.apiChange.next(config);
  // }

  getUpdatedVersion(config: any) {
    this.getVersion(config);
    let versions: any = [];
    this.allSurveyVersions.forEach((x: any) => {
      if (!versions.includes(x.Version)) {
        versions.push(x.Version);
      }
    });
    versions.forEach((tv: any) => {
      this.version_rg.push({
        Type: 'Version',
        Key: tv,
        Name: tv,
        label: tv,
      });
    });
    this.apiChange.next(config);
  }

  getDateGroups(config: any) {
    let dates: any[] = [];
    let local: any =
      Array.isArray(this.gbfService.date_group) && this.gbfService.date_group.length > 0
        ? this.gbfService.date_group
        : getSafeLocalStorage(config.localStorageKey, true, new Array());
    const calendar = this.filterService.getCalenderRange();
    const startDate = moment(calendar[0], this.filterService.dateFormat).add('days', 1).toDate();
    const endDate = moment(calendar[1], this.filterService.dateFormat).toDate();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    let checked_ = false;
    var dateidentiifer = localStorage.getItem('gbf_date_identifier');
    const found = local.find((x: any) => x.label === dateidentiifer);
    if (found) {
      checked_ = true;
    }
    dates.push({
      value: '1',
      label: dateidentiifer,
      IsChecked: checked_,
      Type: 'Date',
    });

    for (let i = startYear; i <= endYear; i++) {
      let sm = 0;
      let em = 11;
      if (startYear === endYear) {
        sm = startMonth;
        em = endMonth;
      } else if (i === endYear) {
        sm = 0;
        em = endMonth;
      } else if (i === startYear) {
        sm = startMonth;
        em = 11;
      }
      for (let j = sm; j <= em; j++) {
        let checked = false;
        const l = `${monthNames[j]} ${i}`;
        const found = local.find((x: any) => x.label === l);
        if (found) {
          checked = true;
        }
        var monthNum = j + 1;
        var monthStr = monthNum.toString().length == 1 ? '0' + monthNum.toString() : monthNum.toString();

        if (this.enableDailyReporting) {
          var dailyReportingAlreadtexists = dates.filter((t) => t.value === 'DailyReporting');

          if (dailyReportingAlreadtexists.length < 1) {
            dates.push({
              label: this.translateService.instant('Daily'),
              value: 'DailyReporting',
              IsChecked: checked,
              Type: 'Date',
            });
          } else {
            dates.filter((t) => t.value === 'DailyReporting')[0].IsChecked = checked;
          }
        }

        // if (monthNum == 1) {
        //   dates.push({
        //     value: `${i}`,
        //     label: `YTD ${i}`,
        //     IsChecked: checked,
        //     Type: 'Date',
        //   });
        // }
        if (monthNum % 3 == 1) {
          dates.push({
            value: `${i}-Q${Math.trunc(monthNum / 3) + 1}`,
            //label: `Q${Math.trunc(monthNum / 3) + 1} ${i}`,
            label: `${this.translateService.instant('Q' + (Math.trunc(monthNum / 3) + 1))} ${i}`,
            IsChecked: checked,
            Type: 'Date',
          });
        }
        dates.push({
          value: `${i}-${monthStr}`,
          label: `${this.translateService.instant(monthNames[j])} ${i}`,
          IsChecked: checked,
          Type: 'Date',
        });
      }
    }
    if (this.filterService.period && dates.length) {
      let checked = false;
      const l = `${this.filterService.period}-${endYear}`;
      const found = local.find((x: any) => x.label === l);
      if (found) {
        checked = true;
      }
      if (
        this.filterService.period.toUpperCase() !== 'CUSTOM RANGE' &&
        this.filterService.period.toUpperCase() !== 'PROGRAM TO DATE' &&
        this.filterService.period.toUpperCase() !== 'PREVIOUS MONTH' &&
        this.filterService.period.toUpperCase() !== 'PREVIOUS QUARTER' &&
        this.filterService.period.toUpperCase() !== 'PREVIOUS YEAR'
      ) {
        dates.push({
          label: `${this.filterService.period}-${endYear}`,
          value: `${this.filterService.period}-${endYear}`,
          IsChecked: checked,
          Type: 'Date',
          RangeType: true,
        });
      }
    } else {
      if (this.filterService.period === '') {
        // dates.push({ label: `${endMonth}-${endYear}`, checked: false, Type: 'Date' });
      } else {
        dates = [];
      }
      let checked = false;
      const found = local.find((x: any) => x.label === 'Date');
      if (found) {
        checked = true;
      }
      // dates.push({ label: `Date`, checked: checked, Type: 'Date' });
    }
    // this.gbfService.date_group = [];
    local = local
      .filter((date: any) => {
        if (dates.find((x: any) => x.value === date.value)) {
          return date;
        }
        return null;
      })
      .filter(Boolean);
    this.date_groups = dates;
    this.gbfService.date_group = local;
    localStorage.setItem(config.localStorageKey, JSON.stringify(local));
    this.apiChange.next(config);
  }
  getCategories(config: any) {
    const url = `/voc/categories`;
    this.http
      .cache()
      .get(`${url}`)
      .subscribe((categories: any) => {
        const mapCategories = this.gbfMapService.mapCategories(categories);
        this.categories = mapCategories;
        this.apiChange.next(config);
      });
  }
  callApi(name: string = '') {
    if (name === '') {
      return;
    }
    const config = this.gbfConfig.filterConfig.config.find((sconfig: any) => {
      return sconfig.name === name;
    });
    if (this[config.property] && this[config.property].length <= 0) {
      this.apiConfig[config.name](config);
    }
  }
  getLocalSelectedVal(config: any) {
    let localVal: any = { selectedTreeItems: [], selected: [] };
    if (config.localStorageKey) {
      try {
        const local = JSON.parse(localStorage.getItem(config.localStorageKey)) || {
          selectedTreeItems: [],
          selected: [],
        };
        localVal = local;
      } catch (e: any) {
        localVal = { selectedTreeItems: [], selected: [] };
      }
    }
    return [...localVal.selectedTreeItems, ...localVal.selected];
  }
  getSurveyResponse(config: any, completed?: Function) {
    this.gbfService.surveyResponse = [];
    const localVal = this.getLocalSelectedVal(config);
    const sv = this.gbfService.version.value;
    if (this.gbfService.authorizationData && this.gbfService.authorizationData.DefaultLanguage) {
      const url = `/voc/response/${sv}/${this.gbfService.authorizationData.DefaultLanguage}`;
      this.http
        .disableLoader()
        .smartLoader()
        .cache()
        .get(`${url}`)
        .subscribe((surveyResponses: any[]) => {
          //surveyResponses.sort((a: any, b: any) => (a.Id > b.Id ? 1 : -1));
          surveyResponses = surveyResponses.map((val: any) => {
            val.level = 1;
            const childrens = val.Options.map((option: any) => {
              const foundOption = localVal.find((x: any) => x.id === option.Id);
              if (foundOption) {
                option.IsChecked = true;
                option.IsPartialChecked = false;
                option.active = foundOption.active;
              }
              option.label = option.Title;
              option.Title = option.Title;
              option.Label = option.Title;
              option.value = option.Id;
              option.name = option.Id;
              option.Name = option.Id;
              option.IsChecked = true;
              option.id = option.Id;
              option.level = 2;
              option.parent = val.Id;
              return option;
            });
            const found = localVal.find((x: any) => x.id === val.Id);
            if (found) {
              val.IsChecked = true;
              val.IsPartialChecked = false;
              val.active = found.active;
            }
            val.children = childrens;
            val.parent = 'null';
            val.level = 1;
            val.id = val.Id;
            val.label = val.Id;
            val.Label = val.Id;
            val.name = val.Id;
            val.Name = val.Id;
            val.mProps = val;
            val.Title = val.Title;
            val.title = val.Title;
            val.IsChecked = true;
            val.Title = val.Title;
            val.title = val.Title;
            return val;
          });
          this.gbfService['surveyResponse'] = surveyResponses;
          this.gbfService['surveyResponse_full'] = {
            customTree: {},
            selectedTreeItems: [],
            selected: surveyResponses,
            breadcrums: [],
            tree: {},
          };
          this.surveyResponses = surveyResponses;
          if (completed) {
            completed(1);
          }
          this.apiChange.next(config);
        });
    }
  }
  getSurveyQuestion(config: any, completed?: Function) {
    const sv = this.gbfService.version.value;
    if (this.gbfService.authorizationData && this.gbfService.authorizationData.DefaultLanguage) {
      const url = `/voc/questions/${sv}/${this.gbfService.authorizationData.DefaultLanguage}`;
      this.http
        .disableLoader()
        .smartLoader()
        .cache()
        .get(`${url}`)
        .subscribe((surveyQuestions: any[]) => {
          surveyQuestions = surveyQuestions.map((val: any) => {
            val.IsChecked = true;
            val.IsSelected = false;
            val.label = val.Id;
            val.Options = val.Options.map((opt: any) => {
              opt.IsChecked = true;
              opt.IsSelected = false;
              return opt;
            });
            return val;
          });
          this.gbfService['surveyQuestion'] = surveyQuestions;
          this.surveyQuestions = surveyQuestions;
          if (completed) {
            completed(1);
          }
          this.apiChange.next(config);
        });
    }
  }
  getLanguages(config: any) {
    const url = `/voc/languages`;
    this.http
      .cache()
      .get(`${url}`)
      .subscribe((languages: any[]) => {
        languages = languages.map((language: any) => {
          language.label = language.Name;
          language.IsChecked = true;
          language.value = language.LanguageCode;
          return language;
        });
        this.languages = this.getCheckedValues(config.name, languages, config.settings.multi);
      });
  }
  getVersion(config: any) {
    this.allSurveyVersions = JSON.parse(localStorage.getItem('surveyVersions'));
    const url = `/surveylist/GetSurveyVersions`;
    if (!this.allSurveyVersions) {
      this.http.get(url).subscribe((res: any) => {
        if (res) {
          res = res.map((r: any) => {
            r.label = r.SurveyLabel || r.Version;
            //r.label = r.Version;
            r.value = r.SurveyId;

            if (r.CurrentSurvey) {
              r.active = true;
            }

            return r;
          });
          this.allSurveyVersions = res;
          localStorage.setItem('surveyVersions', JSON.stringify(this.allSurveyVersions));
          this.mapVersionList(config);
        }
      });
    } else {
      this.mapVersionList(config);
    }
  }
  mapVersionList(config: any) {
    const LScurrentVersion = localStorage.getItem('gbf_version');
    let currentVersion: any = this.gbfService.version;

    const LCurrenTab = JSON.parse(localStorage.getItem('gbf_tab'));
    if (this.gbfService.program == '') this.gbfService.program = LCurrenTab.value;

    if (LScurrentVersion) {
      currentVersion = JSON.parse(LScurrentVersion);
    }
    this.versions = (this.allSurveyVersions || []).filter((x: any) => x.Event === this.gbfService.program);
    if (Array.isArray(this.versions) && this.versions && this.versions.length > 0) {
      let activeVersion = (this.versions || []).find(
        (x: any) => x.Version === currentVersion.version || x.Version === currentVersion.value
      );
      if (this.gbfService.getSurveyVersion()) {
        if (!activeVersion) {
          activeVersion = this.versions.find(
            (x: any) => x.SurveyId === this.gbfService.getSurveyVersion() && x.Event === this.gbfService.program
          );
        }
        if (activeVersion && activeVersion.SurveyId) {
          this.gbfService.setSurveyVersion({
            label: activeVersion.SurveyLabel || activeVersion.Version,
            //label:  activeVersion.Version,
            value: activeVersion.SurveyId,
            version: activeVersion.SurveyId,
            versionName: activeVersion.Version,
          });
          this.versions?.forEach((x: any) => {
            if (currentVersion?.value === x.SurveyId) {
              x.CurrentSurvey = true;
              x.active = true;
            } else {
              x.CurrentSurvey = false;
              x.active = false;
            }
          });
        } else {
          if (!activeVersion) {
            activeVersion = this.versions.find(
              (x: any) => x.CurrentSurvey === true && x.Event === this.gbfService.program
            );
            activeVersion.active = true;
          }
          if (activeVersion && activeVersion.SurveyId) {
            this.gbfService.setSurveyVersion({
              label: activeVersion.SurveyLabel || activeVersion.Version,
              value: activeVersion.SurveyId,
              version: activeVersion.SurveyId,
              versionName: activeVersion.Version,
            });
          } else {
            if (!activeVersion) {
              activeVersion = this.versions.find((x: any) => x.Event === this.gbfService.program);
            }
            if (activeVersion) {
              this.gbfService.setSurveyVersion({
                label: activeVersion.Version,
                value: activeVersion.SurveyId,
                version: activeVersion.SurveyId,
                versionName: activeVersion.Version,
              });
            } else {
              this.gbfService.setSurveyVersion({
                label: '',
                value: '',
                version: '',
                versionName: '',
              });
            }
          }
        }
      } else if (currentVersion && currentVersion.value && currentVersion.label) {
        this.gbfService.setSurveyVersion({
          label: currentVersion.label || currentVersion.Version,
          value: currentVersion.value || currentVersion.SurveyId,
          version: currentVersion.value || currentVersion.SurveyId,
          versionName: currentVersion.label || currentVersion.Version,
        });
      } else {
        if (!activeVersion) {
          activeVersion = this.versions.find(
            (x: any) => x.CurrentSurvey === true && x.Event === this.gbfService.program
          );
        }
        if (activeVersion && activeVersion.SurveyId) {
          this.gbfService.setSurveyVersion({
            label: activeVersion.Version,
            value: activeVersion.SurveyId,
            version: activeVersion.SurveyId,
            versionName: activeVersion.Version,
          });
        } else {
          if (!activeVersion) {
            activeVersion = this.versions.find((x: any) => x.Event === this.gbfService.program);
          }
          if (activeVersion) {
            this.gbfService.setSurveyVersion({
              label: activeVersion.Version,
              value: activeVersion.SurveyId,
              version: activeVersion.SurveyId,
              versionName: activeVersion.Version,
            });
          } else {
            this.gbfService.setSurveyVersion({
              label: '',
              value: '',
              version: '',
              versionName: '',
            });
          }
        }
      }
    } else {
      this.gbfService.setSurveyVersion({
        label: '',
        value: '',
        version: '',
        versionName: '',
      });
    }
  }
  getVindecoderData(config: any) {
    const url = `/Vindecoder/GetVindecoderFilters`;
    this.http
      .cache()
      .get(url)
      .subscribe((res: any) => {
        if (config.yearGroups && config.yearGroups.length > 0) {
          res = res.map((l1: any) => {
            l1.children = l1.children.map((l2: any) => {
              config.yearGroups.forEach((yg: any) => {
                const years = l2.year
                  .filter((y: any) => this.gbfService.applyYearGroupPredicate(yg, y.year))
                  ?.map((x: any) => x.year);
                if (years && years.length > 0) {
                  l2.year.push({ year: yg, yearGroupYears: years });
                }
              });
              return l2;
            });
            return l1;
          });
        }
        this.filterService.modelsTypes = res;
        if (config.mapping === 'ybm') {
          this.models = this.gbfMapService.yearBrandModel(res);
          this.multi_models = config.mergeChilds ? this.gbfMapService.yearBrandModelDistinct(res) : this.models;
        } else {
          this.models = this.gbfMapService.brandModelYear(res);
          this.multi_models = this.models;
        }
        if (this.multi_models.filter((x: any) => x.Label === 'All Except Older' || x.Label === 'All').length > 0) {
          let allexcepolder: any = {};
          let all: any = {};
          this.multi_models.forEach((element: any) => {
            if (element.Label === 'All Except Older') {
              element.hasChild = false;
              element.children = [];
              allexcepolder = element;
            } else if (element.Label === 'All') {
              element.hasChild = false;
              element.children = [];
              all = element;
            }
          });
          var selectedIdx = this.multi_models.findIndex((x: any) => x.Label === 'All');
          this.multi_models.splice(selectedIdx, 1);
          this.multi_models.splice(0, 0, all);
          selectedIdx = this.multi_models.findIndex((x: any) => x.Label === 'All Except Older');
          this.multi_models.splice(selectedIdx, 1);
          this.multi_models.splice(1, 0, allexcepolder);
        }
        this.apiChange.next(config);
        const conx = this.gbfConfig.filterConfig.config.find((sconfig: any) => sconfig.name === 'multi_model');
        if (conx) {
          this.apiChange.next(conx);
        }
      });
  }
  getOrgs(config: any) {
    const foundInitialState = this.gbfConfig.filterConfig['initialStates'][config.name];
    if (foundInitialState) {
      this.gbfService[config.name + '_initialState'] = foundInitialState;
      this.gbfService[config.name + '_breadcrums'] = [foundInitialState];
    }
    const values = { dealer: { label: '', value: 'All' } };
    if (config.dependon && config.dependon.length) {
      config.dependon.forEach((d: string) => {
        values[d] = this.gbfService[d];
      });
    }
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    const orgIdentifier = h.OrgIdentifier;
    let type;
    switch (values.dealer.value) {
      case 'All':
        type = JSON.stringify(['Private', 'Corporate']);
        break;
      default:
        type = `${JSON.stringify([values.dealer.value])}`;
        break;
    }
    const url = `/reporting/orgList?orgIdentifier=${orgIdentifier}&Fields=${type}`;
    this.http
      .get(url)
      .pipe(
        map((res: any) => {
          this[config.property] = res;
          return res;
          // return this.gbfMapService.mapOrgData(res);
        })
      )
      .subscribe((res) => {
        this.orgs = res;
        this.multi_orgs = res;
        this.filterService.orgList = res;
        this.apiChange.next(config);
        const conx = this.gbfConfig.filterConfig.config.find((sconfig: any) => sconfig.name === 'multi_org');
        if (conx) {
          this.apiChange.next(conx);
        }
      });
  }
  getCheckedValues(name: string, values: any[], multi: boolean) {
    if (values && multi) {
      let checkedValue = this.gbfService[name];
      if (checkedValue && checkedValue.length > 0) {
        return values.map((x: any) => {
          const IsChecked = checkedValue.find((y: any) => y.value === x.value)
            ? checkedValue.find((y: any) => y.value === x.value).IsChecked
            : false;
          return { ...x, IsChecked, active: IsChecked };
        });
      } else {
        return values;
      }
    } else if (values && !multi) {
      const checkedValue = this.gbfService[name];
      if (checkedValue) {
        return values.map((x: any) => {
          const IsChecked = x.value === checkedValue.value ? true : false;
          return { ...x, IsChecked, active: IsChecked };
        });
      } else {
        return values;
      }
    } else {
      return [];
    }
  }

  public getConfiguration(id: string, module: string): Observable<any> {
    const user = this.authService.getUser();
    const url = `/configuration?Id=${id}&UserId=${user.Id}&ClientId=${user.ClientId}&Module=${module}`;
    return this.http.disableLoader().disableHttpCancel().get<any>(url);
  }

  public saveConfiguration(obj: any, module: string, id: string) {
    const user = this.authService.getUser();
    this.updateConfiguration({
      UserId: user.Id,
      ClientId: user.ClientId,
      Module: module,
      Settings: obj,
      CreatedBy: user.Id,
      ModifiedBy: user.Id,
      CreatedOn: new Date(),
      ModifiedOn: new Date(),
      IsDeleted: false,
      Id: id,
    }).subscribe((resp: any) => {});
  }
  private updateConfiguration(data: any): Observable<RestMessage> {
    const url = '/configuration';
    return this.http.disableLoader().put<RestMessage>(url, data);
  }
}
